import logo from './logo.svg';
import './App.css';
import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import {useState, useEffect, useMemo} from 'react'
import toast from 'react-hot-toast';
import Header from './components/Header'
import api from './utils/Api'
import _ from 'lodash'
import moment from 'moment'
import { CSVLink, CSVDownload } from "react-csv";

const DatepickerInput = ({ ...props }) => {
  return <Form.Control {...props} readOnly={!props.enableTyping} />
};


const sectorCategories = [
  'Auto',
  'Gaming',
  'Beauty/fashion',
  'Health',
  'E-commerce',
  'Retail',
  'CPG',
  'Finance',
  'Travel',
  'Consumer Electronics',
  'Telco',
  'Media/Entertainment',
  'B2B'
]

const getCurrentQuarter = () => {
  const y = moment().format('YY')
  const m = moment().format('M')
  let q = 1

  if (m >= 4 && m <= 6) {
    q = 2
  } else if (m >= 7 && m <= 9) {
    q = 3
  } else if (m >= 10) {
    q = 4
  }

  return q+'Q'+y
}

function SourceInput() {
  const [form, setForm] = useState({sourceType: 'Brand', quarter: getCurrentQuarter()})
  const user = JSON.parse(localStorage.getItem('@PLI:user'))

  const quarters = useMemo(()=>{
    const a = []

    for (let i = 24; i<=30 ; i++) {
      for (let j = 1; j <= 4 ; j++) {
        a.push(j + 'Q' + i)
      }
    }

    return a
  }, [])

  // useEffect(()=>{
  //   if (!form.sourceType || !form.quarter) return
  //
  //   api.request('source-input/latest', (r)=>{
  //     if (!r) {
  //       setForm({
  //         ...form,
  //         interviewNotes: '',
  //         comments: '',
  //       })
  //
  //       return
  //     }
  //     setForm({...form, ...r})
  //   }, {sourceType: form.sourceType, quarter: form.quarter})
  //
  // }, [form.sourceType, form.quarter])

  return (
    <div className="App">
      <Header />
      <div className="form-container">
      <Form onSubmit={(e)=>{
        e.preventDefault()
        const err = []

        if (!form.quarter) {
          err.push('Quarter is required.')
        }

        if (!form.sectorCategory) {
          err.push('Sector Category is required.')
        }

        if (!form.sourceType) {
          err.push('Source Type is required.')
        }

        if (err.length) {
          toast.error(err.join("\n"))
          return
        }

        api.request('source-input', (r,s)=>{
          toast.success('Submit successfully !')
        }, api.form({...form, email: user.email}), 'PATCH')

      }}>
      <Container>
        <Row>
          <Col xs={12} className="mt-3">
            <Form.Label>Quarter <span className="text-danger">*</span></Form.Label>
            <Form.Select onChange={(e)=>{
              setForm({...form, quarter: e.target.value})
            }} aria-label="Default select example">
            <option data-hook="native-option-PLACEHOLDER" data-index="0" value="Select an option">
            Select an option</option>
            {
              quarters.map((ticker, index)=>(
                <option selected={ticker === form.quarter} key={index} value={ticker}>{ticker}</option>
              ))
            }
            </Form.Select>
          </Col>
          <Col xs={12} className="mt-3">
              <div><Form.Label>Source Type <span className="text-danger">*</span></Form.Label></div>
              <Form.Check
                inline
                label="Brand"
                name="sourceType"
                type={'radio'}
                id={`brand-brand`}
                checked={form.sourceType === 'Brand'}
                onChange={(e)=>setForm({...form, sourceType: 'Brand'})}
              />
              <Form.Check
                inline
                name="sourceType"
                label="DR"
                type={'radio'}
                id={`brand-na`}
                checked={form.sourceType === 'DR'}
                onChange={(e)=>setForm({...form, sourceType: 'DR'})}
              />
          </Col>
          {/*<Col xs={12} className="mt-3">
              <div><Form.Label>Source DR <span className="text-danger">*</span></Form.Label></div>
              <Form.Check
                inline
                label="DR"
                name="sourceDr"
                type={'radio'}
                id={`dr-dr`}
                checked={form.sourceDr === 'DR'}
                onChange={(e)=>setForm({...form, sourceDr: e.target.checked?'DR':''})}
              />
              <Form.Check
                inline
                name="sourceDr"
                label="N/A"
                type={'radio'}
                id={`dr-na`}
                checked={form.sourceDr === 'N/A'}
                onChange={(e)=>setForm({...form, sourceDr: e.target.checked?'N/A':''})}
              />
          </Col>*/}
          <Col xs={12} className="mt-3">
            <Form.Label>Sector Category <span className="text-danger">*</span></Form.Label>
            <Form.Select onChange={(e)=>{
              setForm({...form, sectorCategory: e.target.value})
            }} aria-label="Default select example">
            <option data-hook="native-option-PLACEHOLDER" data-index="0" value="Select an option">
            Select an option</option>
            {
              sectorCategories.map((ticker, index)=>(
                <option selected={ticker === form.sectorCategory} key={index} value={ticker}>{ticker}</option>
              ))
            }
            </Form.Select>
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Interview Notes<span className="text-danger">*</span></Form.Label>
            <Form.Control name="interviewNotes" onChange={(e)=>setForm({...form, interviewNotes: e.target.value})}
              value={form.interviewNotes} as="textarea" className="mh-400"
             placeholder="Enter text here" required />
          </Col>
          <Col xs={12}  className="mt-3">
            <Form.Label>Comments<span className="text-danger">*</span></Form.Label>
            <Form.Control name="comments" onChange={(e)=>setForm({...form, comments: e.target.value})}
              value={form.comments} as="textarea" className="mh-400"
             placeholder="Enter text here" required />
          </Col>
          <Col xs={12} className="d-flex justify-content-center my-3">
            <Button type="submit">Submit</Button>
          </Col>
        </Row>
      </Container>
      </Form>
      </div>
    </div>
  );
}

export default SourceInput;
